/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Text, SeparateLine, SeparateLineWrap } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"nasteam"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-4oj0tp --center pb--80 pt--80" name={"uvod"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/17142/d86b2f3e0e3a493ab8c56a08efd1e9c6_con=110_ove=000000x25__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17142/d86b2f3e0e3a493ab8c56a08efd1e9c6_con=110_ove=000000x25__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/17142/d86b2f3e0e3a493ab8c56a08efd1e9c6_con=110_ove=000000x25__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17142/d86b2f3e0e3a493ab8c56a08efd1e9c6_con=110_ove=000000x25__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/17142/d86b2f3e0e3a493ab8c56a08efd1e9c6_con=110_ove=000000x25__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/17142/d86b2f3e0e3a493ab8c56a08efd1e9c6_con=110_ove=000000x25__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17142/d86b2f3e0e3a493ab8c56a08efd1e9c6_con=110_ove=000000x25__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17142/d86b2f3e0e3a493ab8c56a08efd1e9c6_con=110_ove=000000x25__s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--62" style={{"maxWidth":1000}} content={"<span style=\"color: var(--color-dominant);\"><br><br><br><br><br><br><br></span><br><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"r9qvd9wz72s"} layout={"l4"}>
          
          <ColumnWrap className="column__flex --center el--4 --full" columns={"4"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/17142/718147dfe7ee434fbffb882a5f60716c_e=338x0x809x809_s=660x_ove=008cffx8_.jpeg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/17142/718147dfe7ee434fbffb882a5f60716c_e=338x0x809x809_s=350x_ove=008cffx8_.jpeg 350w, https://cdn.swbpg.com/t/17142/718147dfe7ee434fbffb882a5f60716c_e=338x0x809x809_s=660x_ove=008cffx8_.jpeg 660w"}>
              </Image>

              <Text className="text-box" content={"<span style=\"font-weight: bold;\"><span style=\"color: rgb(248, 5, 5);\">Ladislav Samek / PDF UPOL<br></span><span style=\"color: rgb(6, 6, 245);\">ředitel a zakladatel organizace&nbsp;<br></span><br><span style=\"color: var(--black);\">ladislav.samek@detivakci.org\n<br>+420 735 239 759</span></span><span style=\"font-weight: bold; color: var(--black);\"><br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/17142/3e2f278da0de4edeabf37c144d9ecaf6_e=0x122x1633x1634_s=860x_ove=008cffx8_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/17142/3e2f278da0de4edeabf37c144d9ecaf6_e=0x122x1633x1634_s=350x_ove=008cffx8_.jpg 350w, https://cdn.swbpg.com/t/17142/3e2f278da0de4edeabf37c144d9ecaf6_e=0x122x1633x1634_s=660x_ove=008cffx8_.jpg 660w, https://cdn.swbpg.com/t/17142/3e2f278da0de4edeabf37c144d9ecaf6_e=0x122x1633x1634_s=860x_ove=008cffx8_.jpg 860w, https://cdn.swbpg.com/t/17142/3e2f278da0de4edeabf37c144d9ecaf6_e=0x122x1633x1634_s=1400x_ove=008cffx8_.jpg 1400w"}>
              </Image>

              <Text className="text-box" content={"<span style=\"font-weight: bold;\"><span style=\"color: rgb(248, 5, 5);\">Klára Vavrušková , DiS.&nbsp;<br></span><span style=\"color: rgb(6, 6, 245);\">patronka organizace&nbsp;<br></span><br><span style=\"color: var(--black);\">info@detivakci.org\n<br>+420 735 239 759</span></span><span style=\"font-weight: bold; color: var(--black);\"><br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/17142/6a6d3bd63db44f539f3c328b49281ef8_e=0x205x1633x1634_s=860x_ove=008cffx8_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/17142/6a6d3bd63db44f539f3c328b49281ef8_e=0x205x1633x1634_s=350x_ove=008cffx8_.jpg 350w, https://cdn.swbpg.com/t/17142/6a6d3bd63db44f539f3c328b49281ef8_e=0x205x1633x1634_s=660x_ove=008cffx8_.jpg 660w, https://cdn.swbpg.com/t/17142/6a6d3bd63db44f539f3c328b49281ef8_e=0x205x1633x1634_s=860x_ove=008cffx8_.jpg 860w, https://cdn.swbpg.com/t/17142/6a6d3bd63db44f539f3c328b49281ef8_e=0x205x1633x1634_s=1400x_ove=008cffx8_.jpg 1400w"}>
              </Image>

              <Text className="text-box" content={"<span style=\"font-weight: bold;\"><span style=\"color: rgb(248, 5, 5);\">Bc. Kateřina Lévai<br></span><span style=\"color: rgb(6, 6, 245);\">členka Rady organizace<br></span><br><span style=\"color: var(--black);\">info@detivakci.org\n<br>+420 735 239 759</span></span><span style=\"font-weight: bold; color: var(--black);\"><br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/17142/b3b9a444793440ddbaafa2a16d3accf2_e=0x0x1633x1632_s=860x_ove=008cffx8_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/17142/b3b9a444793440ddbaafa2a16d3accf2_e=0x0x1633x1632_s=350x_ove=008cffx8_.jpg 350w, https://cdn.swbpg.com/t/17142/b3b9a444793440ddbaafa2a16d3accf2_e=0x0x1633x1632_s=660x_ove=008cffx8_.jpg 660w, https://cdn.swbpg.com/t/17142/b3b9a444793440ddbaafa2a16d3accf2_e=0x0x1633x1632_s=860x_ove=008cffx8_.jpg 860w, https://cdn.swbpg.com/t/17142/b3b9a444793440ddbaafa2a16d3accf2_e=0x0x1633x1632_s=1400x_ove=008cffx8_.jpg 1400w"}>
              </Image>

              <Text className="text-box" content={"<span style=\"font-weight: bold;\"><span style=\"color: rgb(248, 5, 5);\">Mgr. Elen Sytko<br></span><span style=\"color: rgb(6, 6, 245);\">členka Rady organizace<br></span><br><span style=\"color: var(--black);\">info@detivakci.org\n<br>+420 735 239 759</span></span><span style=\"font-weight: bold; color: var(--black);\"><br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--4 --full" columns={"4"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/17142/f19b4e6b2a3b40a09a1ee28b02f375d1_e=0x166x1633x1634_s=860x_ove=008cffx8_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/17142/f19b4e6b2a3b40a09a1ee28b02f375d1_e=0x166x1633x1634_s=350x_ove=008cffx8_.jpg 350w, https://cdn.swbpg.com/t/17142/f19b4e6b2a3b40a09a1ee28b02f375d1_e=0x166x1633x1634_s=660x_ove=008cffx8_.jpg 660w, https://cdn.swbpg.com/t/17142/f19b4e6b2a3b40a09a1ee28b02f375d1_e=0x166x1633x1634_s=860x_ove=008cffx8_.jpg 860w, https://cdn.swbpg.com/t/17142/f19b4e6b2a3b40a09a1ee28b02f375d1_e=0x166x1633x1634_s=1400x_ove=008cffx8_.jpg 1400w"}>
              </Image>

              <Text className="text-box" content={"<span style=\"font-weight: bold;\"><span style=\"color: rgb(248, 5, 5);\">Tomáš Soukup<br></span><span style=\"color: rgb(6, 6, 245);\">technická sekce organizace<br></span><br><span style=\"color: var(--black);\">info@detivakci.org\n<br>+420 735 239 759</span></span><span style=\"font-weight: bold; color: var(--black);\"><br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/17142/fe9e9a1c38c34385a39f6283abd6dc63_e=0x143x1633x1632_s=860x_ove=008cffx8_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/17142/fe9e9a1c38c34385a39f6283abd6dc63_e=0x143x1633x1632_s=350x_ove=008cffx8_.jpg 350w, https://cdn.swbpg.com/t/17142/fe9e9a1c38c34385a39f6283abd6dc63_e=0x143x1633x1632_s=660x_ove=008cffx8_.jpg 660w, https://cdn.swbpg.com/t/17142/fe9e9a1c38c34385a39f6283abd6dc63_e=0x143x1633x1632_s=860x_ove=008cffx8_.jpg 860w, https://cdn.swbpg.com/t/17142/fe9e9a1c38c34385a39f6283abd6dc63_e=0x143x1633x1632_s=1400x_ove=008cffx8_.jpg 1400w"}>
              </Image>

              <Text className="text-box" content={"<span style=\"font-weight: bold;\"><span style=\"color: rgb(248, 5, 5);\">Kateřina Kusá<br></span><span style=\"color: rgb(6, 6, 245);\">organizační sekce organizace<br></span><br><span style=\"color: var(--black);\">info@detivakci.org\n<br>+420 735 239 759</span></span><span style=\"font-weight: bold; color: var(--black);\"><br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/17142/5acad5baeeb645649fbfdb5cf7206f91_e=0x189x1633x1632_s=860x_ove=008cffx8_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/17142/5acad5baeeb645649fbfdb5cf7206f91_e=0x189x1633x1632_s=350x_ove=008cffx8_.jpg 350w, https://cdn.swbpg.com/t/17142/5acad5baeeb645649fbfdb5cf7206f91_e=0x189x1633x1632_s=660x_ove=008cffx8_.jpg 660w, https://cdn.swbpg.com/t/17142/5acad5baeeb645649fbfdb5cf7206f91_e=0x189x1633x1632_s=860x_ove=008cffx8_.jpg 860w, https://cdn.swbpg.com/t/17142/5acad5baeeb645649fbfdb5cf7206f91_e=0x189x1633x1632_s=1400x_ove=008cffx8_.jpg 1400w"}>
              </Image>

              <Text className="text-box" content={"<span style=\"font-weight: bold;\"><span style=\"color: rgb(248, 5, 5);\">Mgr. Tereza Čaladi<br></span><span style=\"color: rgb(6, 6, 245);\">PR sekce organizace<br></span><br><span style=\"color: var(--black);\">info@detivakci.org\n<br>+420 735 239 759</span></span><span style=\"font-weight: bold; color: var(--black);\"><br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/17142/ced2962b30ed46edb2bb61ec8a370e1f_e=0x31x1633x1632_s=860x_ove=008cffx8_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/17142/ced2962b30ed46edb2bb61ec8a370e1f_e=0x31x1633x1632_s=350x_ove=008cffx8_.jpg 350w, https://cdn.swbpg.com/t/17142/ced2962b30ed46edb2bb61ec8a370e1f_e=0x31x1633x1632_s=660x_ove=008cffx8_.jpg 660w, https://cdn.swbpg.com/t/17142/ced2962b30ed46edb2bb61ec8a370e1f_e=0x31x1633x1632_s=860x_ove=008cffx8_.jpg 860w, https://cdn.swbpg.com/t/17142/ced2962b30ed46edb2bb61ec8a370e1f_e=0x31x1633x1632_s=1400x_ove=008cffx8_.jpg 1400w"}>
              </Image>

              <Text className="text-box" content={"<span style=\"font-weight: bold;\"><span style=\"color: rgb(248, 5, 5);\">Mgr. Lydia Petera<br></span><span style=\"color: rgb(6, 6, 245);\">provozní sekce organizace<br></span><br><span style=\"color: var(--black);\">info@detivakci.org\n<br>+420 735 239 759</span></span><span style=\"font-weight: bold; color: var(--black);\"><br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"jega946n9a"}>
          
          <SeparateLineWrap className="--full" fullscreen={true}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column name={"18ogzer1ysr"} style={{"paddingTop":0,"paddingBottom":0}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box fs--18" style={{"maxWidth":650}} content={"<span style=\"color: var(--black);\"><span style=\"font-weight: bold;\">Fotograf, kameraman:</span> Mgr. Jan Klepárník&nbsp;<br><span style=\"font-weight: bold;\"><br>Řidiči: </span>Filip Drábek, Martin Horečka&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"h1y7s2j8qww"}>
          
          <SeparateLineWrap className="--full" fullscreen={true}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--center" name={"ygjc146ncwm"} style={{"paddingTop":0,"paddingBottom":0}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--26" content={"<span style=\"font-weight: bold; color: rgb(247, 35, 5);\">Odborný tým organizace&nbsp;</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"b9hm01ytye"}>
          
          <SeparateLineWrap className="--full" fullscreen={true}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column name={"4kntx0jmewf"} style={{"paddingTop":62,"paddingBottom":76}}>
          
          <ColumnWrap className="column__flex --center el--3 --full" columns={"3"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Text className="text-box fs--14" content={"<span style=\"color: var(--black);\"><span style=\"font-weight: bold;\">PhDr., Mgr. Sofie Wernerová<br></span>&nbsp;psychoterapeutka<br>ředitelka dětského domova Krásná Lípa&nbsp;&nbsp;<br><br><span style=\"font-weight: bold;\"><br>Mgr. Lucie Kupková&nbsp;</span><br>ředitelka dětského domova Litovel&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box fs--14" content={"<span style=\"color: var(--black);\">&nbsp;<span style=\"font-weight: bold;\">PhDr., Mgr. Sáva Arabadžiev</span><br>psychoterapeut, soudní znalec<br>ředitel výchovného ústavu Pšov&nbsp;<br><br><span style=\"font-weight: bold;\"><br>Mgr. Kateřina Beťáková</span>&nbsp;<br>ředitelka dětského domova Černá Voda&nbsp;&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box fs--14" content={"<span style=\"color: var(--black);\"><span style=\"font-weight: bold;\">&nbsp;Ing. Aleš Matějíček, Ph.D.&nbsp;</span><br>speciální pedagog&nbsp;<br>dětský diagnostický ústav Hradec Králové</span><br>&nbsp;<br><span style=\"color: var(--black);\"><span style=\"font-weight: bold;\"><br>Mgr. Zdeněk Kropáč</span><br>ředitel dětského domova Tachov&nbsp;&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3 --full" columns={"3"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Text className="text-box fs--14" content={"<span style=\"color: var(--black);\"><span style=\"font-weight: bold;\">Mgr. Jan Vavřík</span>&nbsp;<br>ředitel dětského domova Vrbno pod Pradědem&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box fs--14" content={"<span style=\"color: var(--black);\"><span style=\"font-weight: bold;\">Mgr. Vladimír Brablec</span>&nbsp;<br>ředitel dětského domova Prostějov&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box fs--14" content={"<span style=\"color: var(--black);\"><span style=\"font-weight: bold;\">Mgr. Jana Budáková, MBA</span><br>pedagogog, supervizor, výchovný poradce</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" name={"nml41d7rzyn"} style={{"paddingTop":0,"paddingBottom":0}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"xa3s6blb0k"}>
          
          <SeparateLineWrap className="--full" fullscreen={true}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="css-14l60qc css-42e4bw --full pb--60" name={"xw9b5m8m7n"} style={{"paddingTop":0}} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/8f8aef85b4584d2bb9e0e053dd92e3d7_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/8f8aef85b4584d2bb9e0e053dd92e3d7_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/8f8aef85b4584d2bb9e0e053dd92e3d7_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/8f8aef85b4584d2bb9e0e053dd92e3d7_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/8f8aef85b4584d2bb9e0e053dd92e3d7_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/8f8aef85b4584d2bb9e0e053dd92e3d7_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/8f8aef85b4584d2bb9e0e053dd92e3d7_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/8f8aef85b4584d2bb9e0e053dd92e3d7_s=3000x_.jpg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--43 title-box--invert" content={"<a href=\"https://www.uol.cz/\" target=\"_blank\"><span style=\"font-weight: bold; color: var(--white);\">Účetnictví nám vede UOL a.s.<br><br><br><br><br><br><br><br><br><br><br></span><br></a><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}